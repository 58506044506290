<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | TOOLKIT` : `TOOLKIT` }}</template>
    </metainfo>
    <router-view></router-view>
    <global-modal />
  </div>
</template>

<script>
import Fingerprint2 from "fingerprintjs2"; // 引入fingerprintjs2
import globalModal from "@/components/boolv-ui/global-modal";
import { useRoute } from 'vue-router';
import { watch } from 'vue'
var md5 = require("md5");

export default {
  name: "App",
  components: {
    globalModal,
  },
  async created () {
    // 不应在页面加载时或加载后直接运行指纹。 而是使用setTimeout或requestIdleCallback将其延迟几毫秒，以确保指纹一致。
    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        this.createFingerprint();
      });
    } else {
      setTimeout(() => {
        this.createFingerprint();
      }, 500);
    }
    this.createSessionId();

  },
  async mounted () {
    // 记录来源埋点
    const route = useRoute();
    watch(
      () => route.query.utm_source,
      newSource => {
        if (newSource) {
          sessionStorage.setItem("utm_source", newSource)
        }
      }
    )
  },
  mounted () {
    const route = useRoute();
    watch(
      () => route.query?.utm_source,
      newSource => {
        if (newSource) {
          sessionStorage.setItem("utm_source", newSource);
        }
      }
    )

  },
  methods: {

    // 创建浏览器指纹
    createFingerprint () {
      // 浏览器指纹
      const fingerprint = Fingerprint2.get((components) => {
        // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
        const values = components.map((component) => component.value); // 配置的值的数组
        const murmur = Fingerprint2.x64hash128(values.join(""), 31); // 生成浏览器指纹
        localStorage.setItem("browserId", murmur); // 存储浏览器指纹，在项目中用于校验用户身份和埋点
      });
    },
    createSessionId () {
      const isHaveSessionId = sessionStorage.getItem("sessionId");
      //判断当前sessionStorage中是否有sessionId 没有则创建保存，有也跳过
      if (isHaveSessionId) {
        return;
      } else {
        const sessionId = md5(Date.now());
        sessionStorage.setItem("sessionId", sessionId);
        return;
      }
    },
  },
};
</script>

<style lang="less">
@font-face {
  font-family: Inter;
  src: url(/fonts/Inter.ttf) format("truetype");
}

body {
  font-family: Inter, Arial, sans-serif !important;
  -webkit-font-smoothing: subpixel-antialiased !important; 
  text-rendering:optimizeLegibility !important;
}

#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  &>div {
    width: 100%;
    height: 100%;
  }

}
</style>
